body {
  /* Production */
  &.env--prod,
  &.env--prod_mc {
    main {
      padding-top: 25px;
      padding-bottom: 25px;

      li {
        &.status {
          p {
            margin-top: 15px;
          }
          h3,
          span.code {
            display: none;
          }
          span.message {
            font-size: 24px;
          }
        }
        &.environment,
        &.hostname {
          display: none;
        }
      }
    }
  }

  /* Production: Memorial Care */
  &.env--prod_mc { 
    background: #f5f5f5;

    header {
      padding-top: 90px;
      background: linear-gradient(65deg, #33006F, #7454b4);

      h1 {
        padding: 6px 12px 12px 24px;
        background: #fff;
        border-radius: 0 0 5px 0;

        span {
          height: 52px;
          background: url("/default_site/_ui/skin/img/logo-mc.png") 0px 0px no-repeat;
          background-size: 174px 52px;
        }
      }
    }
    main {
      margin-top: 100px;
      border-radius: 15px;
      box-shadow: 0 0 20px rgba(51, 0, 111, 0.15);

      li {
        color: #383838;
      }
    }
  }
  
  /* Dev and Stage */
  &.env--stage,
  &.env--dev {
    li.status {
      p {
        margin-bottom: 45px;
      }
    }
  }

  /* Stage */
  &.env--stage {
    ul.report {
      span.code {
        display: none;
      }
    }
  }

  /* Development */
  &.env--dev {
    ul.report {
      span.code {
        &.code--200 {
          color: $green;
        }
        &.code--500,
        &.code--404 {
          color: $error;
        }
      }
    }
  }
}