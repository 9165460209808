main {
  /* Status and Error Reports */
  ul.report {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 10px;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      text-align: center;
      width: 48%;

      &.full-row {
        width: 100%;
      }

      span.code {
        &:after {
          display: inline-block;
          padding: 0 5px;
          content: '\2014';
          color: lighten($dark-gray, 20%);
        }
      }
      span.message {
        position: relative;
      
        span.source {
          display: inline-block;
          position: absolute;
          top: 24px;
          right: 0;
          margin-left: -100px;
          padding-left: 10px;
          font-size: 12px;

          a {
            color: darken($light-gray, 10%);
            transition: color 250ms;

            &:hover {
              color: $dark-gray;
            }
          }
        }
      }
      ul.vhosts {
        li {
          list-style: none;
          padding-bottom: 10px;
          color: lighten($dark-gray, 20%);
        }
      }
    }
  }

  /* Sandbox Site List */
  ul.site-list {
    margin: 0 0 0 30px;
    padding: 0;
    columns: 150px 3;

    li {
      list-style: circle;
      padding-bottom: 5px;

      a {
        color: $orange;
        text-decoration: none;
        transition: color 250ms;

        &:hover {
          color: darken($orange, 20%);
          text-decoration: underline;
        }
      }
    }
  }

  /* Login Form */
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 15px;
    margin-bottom: 15px;
    padding-top: 30px;
    text-align: center;

    p.error {
      margin-bottom: 15px;
      color: $error;
    }

    div.form-item {
      padding-bottom: 15px;
      width: 25%;
      
      &.form-buttons {
        width: 100%;
      }

      label {
        display: block;
        padding: 0 0 6px 0;
      }
      input {
        padding: 12px;
        font-family: $font-family;
        font-size: 16px;
        color: $dark-gray;
        background: #fff url("/default_site/_ui/skin/img/halftone-light-gray.png") repeat;
        background-size: 55px 55px;
        border: 1px solid $light-gray;
        transition: all 500ms;

        &[type=submit] {
          padding: 16px 16px 14px 48px;
          color: #fff;
          font-style: italic;
          background: $orange url("/default_site/_ui/skin/img/double-right-arrow-2x.png") 13px 11px no-repeat;
          background-size: 25px 25px;
          border: 0;
          cursor: pointer;

          &:hover {
            background-color: darken($orange, 5%);
          }
        }
        &:hover {
          border-color: $medium-gray;
        }
        &:focus {
          border-color: $orange;
          outline:0;
          outline: none;
        }
      }
    }
  }
}