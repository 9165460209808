* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: $font-family;
  font-size: 18px;
  color: $dark-gray;
  height: 100%;
  background: $light-gray
}

header {
  padding: 40px 0 30px 0;
  background: linear-gradient($blue-light, $blue-dark);

  h1 {
    display: block;
    position: absolute;
    top: 50px;
    left: 24px;
    padding: 0;

    span {
      @include hide-text();
      display: inline-block;
      width: 28px;
      height: 41px;
      background: url("/default_site/_ui/skin/img/logo-new.png") center left no-repeat;
    }
  }
  h2 {
    color: #fff;
    font-size: 56px;
    line-height: 60px;
    text-transform: uppercase;
    text-align: center;
  }
}

main {
  margin: 75px auto 25px auto;
  padding: 25px;
  max-width: 800px;
  background: #fff;
  border: 1px solid $orange;
  border-radius: 4px;

  h3 {
    margin: 10px 0;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    background: linear-gradient($medium-gray, $light-gray);
    border-radius: 4px;
    border: 1px solid $medium-gray;
  }
  p {
    margin-bottom: 30px;
    padding: 0 15px;
    color: lighten($dark-gray, 20%);
    text-align: center;
  }
  h3 + p {
    margin-top: 15px;
  }
  a {
    color: $orange;
    transition: 500ms;

    &:hover {
      color: darken($orange, 10%);
    }
  }

  .krumo-root {
    ul.krumo-node {
      li {
        text-align: left;
      }
    }
    .krumo-footnote {
      display: none;
    }
  }
}
