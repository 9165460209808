@mixin hide-text {
  /* Hide the text. */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

/* Colors */
$orange: #d68f27;
$dark-gray: #4d4d4f;
$medium-gray: #c2c2c2;
$light-gray: rgb(245, 246, 246);
$error: #9c2727;
$green: #38a338;
$blue-light: #37516f;
$blue-dark: #121b28;

/* Typography */
$font-family: 'Trebuchet MS', Verdana, Arial, sans-serif;
